.root {
    font-family: 'TWK Everett', serif;
    font-size: var(--font-size-component1);
    line-height: var(--line-height-component1);
    font-weight: var(--font-weight-4);
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: var(--spacing-size-two) var(--spacing-size-three);
    white-space: nowrap;
    /* These last two declarations allow for a 'perfect circle' variant of 40 x 40 when the label is a single character (e.g. 'S'). There might be a better way to achieve this? */
    min-width: var(--spacing-size-four);
    justify-content: center;
}

.rootAlt {
    font-family: 'TWK Everett Mono', serif;
    font-size: var(--font-size-component1);
    line-height: var(--line-height-component1);
    font-weight: var(--font-weight-3);
    letter-spacing: -0.5px;
}

.rootNortheast {
    align-items: baseline;
}

.buttonLink {
    text-decoration: none;
}

/* Button Style & Hover */
.base {
    color: var(--text-invert);
    background-color: var(--surface-brand);
}

.base:hover {
    background-color: var(--surface-hover-primary);
}

.ghost {
    border: 1px solid var(--border-primary);
}

.ghost:hover {
    color: var(--text-invert);
    background-color: var(--surface-invert);
    border: 1px solid transparent;
}

.secondaryGhost {
    font-family: 'TWK Everett Mono', serif;
    font-weight: var(--font-weight-3);
    color: var(--color-brand-black);
    background-color: var(--surface-primary);
    border: 1px solid var(--border-secondary);
}

.lightGhost {
    color: var(--color-brand-white);
    border: 1px solid var(--border-secondary);
}

.lightGhost:hover {
    color: var(--color-brand-black);
    background-color: var(--color-brand-white);
    border: 1px solid transparent;
}

.secondary {
    background-color: var(--surface-primary);
}

.secondary:hover {
    background-color: var(--surface-hover-invert);
}

.toggleInactive:hover {
    background-color: var(--surface-hover-invert);
}

.sidebar {
    background-color: var(--color-brand-white);
}

.sidebar:hover {
    background-color: var(--color-brand-aluminium);
    color: var(--color-brand-black);
}

.text {
    text-decoration: underline;
    text-underline-position: under;
}

.text:hover {
    color: var(--text-secondary);
}

.quite {
    padding-inline: 0;
}

.quite:hover {
    color: var(--text-secondary);
}

.quite_alt:hover {
    color: var(--text-secondary);
}

.link_alt {
    text-decoration: underline;
    text-underline-position: under;
}

.link_alt:hover {
    color: var(--text-secondary);
}

.collection {
    background-color: var(--surface-secondary);
}

.collection:hover {
    color: var(--text-invert);
    background-color: var(--surface-hover-primary);
}

.discover {
    background-color: var(--color-brand-discover);
    color: var(--text-invert);
}

.discover:hover {
    background-color: var(--surface-brand);
    color: var(--text-invert);
}

.checkout {
    background-color: var(--color-brand-pay);
}

.checkout:hover {
    color: var(--text-invert);
    background-color: var(--color-brand-pay);
}

.buy {
    background-color: var(--color-brand-buy);
    color: var(--text-invert);
}

.buy:hover {
    background-color: var(--color-brand-cement);
}

.active {
    background-color: var(--color-brand-titanium);
    color: var(--color-brand-white);
}

.active:hover {
    background-color: var(--color-brand-white);
    color: var(--color-brand-titanium);
}

.activeQuite {
    color: var(--color-brand-black);
    text-decoration: underline;
}

/* lightQuite*/
.lightQuite {
    color: var(--color-brand-white);
}

.lightQuite:hover {
    color: var(--color-brand-titanium);
}

/* Disabled Styling */
.disabled {
    font-family: 'TWK Everett', serif;
    font-weight: var(--font-weight-4);
    font-size: var(--font-size-component1);
    line-height: var(--line-height-component1);
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;
    cursor: no-drop;
    padding: var(--spacing-size-two) var(--spacing-size-three);
}

.disabledBase {
    background-color: var(--color-brand-aluminium);
}

.disabledGhost {
    color: var(--text-inactive);
    border: 1px solid var(--color-brand-steel);
}

.disabledSecondary {
    color: var(--text-inactive);
    background-color: var(--surface-primary);
}

.disabledText {
    color: var(--text-inactive);
}

.disabledQuite {
    color: var(--text-inactive);
}

.disabledQuite {
    padding-inline: 0;
}

/* Inactive styling */
.inactive {
    font-family: 'TWK Everett', serif;
    font-weight: var(--font-weight-4);
    font-size: var(--font-size-component1);
    line-height: var(--line-height-component1);
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;
    padding: var(--spacing-size-two) var(--spacing-size-three);
}

.inactiveBase {
    color: var(--color-brand-white);
    background-color: var(--color-brand-aluminium);
}

.inactiveSecondary {
    color: var(--color-brand-steel);
    background-color: var(--color-brand-white);
}

.inactiveQuite {
    color: var(--color-brand-steel);
}

.inactiveQuite {
    padding-inline: 0;
}

.radiusSmall {
    border-radius: var(--border-radius-xxs);
}

.radiusMedium {
    border-radius: var(--border-radius-sm);
}

.radiusLarge {
    border-radius: var(--border-radius-lg);
}

.radiusNone {
    border-radius: 0;
}

/* Icons spacing */
.icon.iconMargin {
    margin-left: var(--spacing-size-two);
}

/* Other controlling options */
.textVariantSpacing {
    padding: var(--spacing-size-two) 0;
}

.paddingZeroInline {
    padding-inline: 0;
}

.paddingZero {
    padding: 0;
}

.rootNortheast {
    align-items: baseline;
}

.buttonLink {
    text-decoration: none;
}

.fullWidth {
    width: 100%;
}

/* Special use case lookbook toggle variant */
.toggleActive {
    background-color: var(--surface-primary);
}

.toggleInactive {
    color: var(--surface-brand);
}
